import React from 'react';
import { useHistory } from 'react-router-dom';

function TestPage() {
  let history = useHistory();

  const finishTest = () => {
    // Logic to handle test submission
    history.push('/result');
  };

  return (
    <div className="test-page">
      <h1>IQ Test</h1>
      {/* ... Test content goes here ... */}
      <button onClick={finishTest} className="finish-button">Finish Test</button>
    </div>
  );
}

export default TestPage;
