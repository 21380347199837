import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  VStack,
  Text,
  Heading,
  Container,
  Flex,
  Image,
  Center,
  Spacer,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import einsteinImage from '../../assets/einstein.jpeg';

function HomePage() {
  let history = useHistory();

  const startTest = () => {
    history.push('/question');
  };

  const viewHistory = () => {
    history.push('/history');
  };

  // Decide the orientation of the button stack based on screen size
  const buttonStackDirection = useBreakpointValue({ base: 'column', md: 'row' });

  return (
    <VStack spacing={10}>
      <Box
        as="header"
        w="full"
        p={4}
        color="white"
        bgGradient="linear(to-r, gray.300, gray.500, gray.300)"
        boxShadow="sm"
      >
        <Container maxW="container.xl">
          <Flex align="center" justify="space-between">
            <Heading size="md">CloudIQTest</Heading>
            <Spacer />
            <Stack direction={buttonStackDirection} spacing={4} align="center">
              <Button variant="outline" onClick={() => history.push('/contact_us')}>Contact Us</Button>
              <Button variant="outline" onClick={viewHistory}>History</Button>
              <Button variant="outline" onClick={() => history.push('/my_account')}>My Account</Button>
              <Button
                colorScheme="teal"
                size="lg"
                rightIcon={<ArrowForwardIcon />}
                onClick={startTest}
                boxShadow="0px 0px 15px rgba(0, 150, 136, 0.6)"
                _hover={{
                  transform: "scale(1.05)",
                  boxShadow: "0px 0px 20px rgba(0, 150, 136, 0.8)",
                }}
              >
                Start Test
              </Button>
            </Stack>
          </Flex>
        </Container>
      </Box>

      <Container maxW="container.xl" centerContent>
        <VStack spacing={5} align="stretch">
          <Center flexDirection="column">
            <Image src={einsteinImage} alt="Einstein" boxSize="150px" borderRadius="full"/>
            <Heading textAlign="center" mt={4}>Welcome to the IQ Test Challenge</Heading>
            <Text fontSize="xl" textAlign="center" mt={2}>
              Test your IQ and compare your score with others. Are you ready to challenge your brain?
            </Text>
          </Center>

          <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
            <Heading size="lg" textAlign="center">Why Our IQ Test?</Heading>
            <Text mt={4} textAlign="center">
              Our IQ test is designed by neuroscientists to provide accurate and meaningful results.
            </Text>
          </Box>

          <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
            <Heading size="lg" textAlign="center">What is an IQ Test?</Heading>
            <Text mt={4} textAlign="center">
              An IQ test measures a range of cognitive abilities and provides a score that is intended to serve as a measure of an individual's intellectual abilities and potential.
            </Text>
          </Box>
        </VStack>
      </Container>

      <Box as="footer" w="full" p={4} bg="gray.700" color="white">
        <Center>
          © {new Date().getFullYear()} CloudIQTest. All rights reserved.
        </Center>
      </Box>
    </VStack>
  );
}

export default HomePage;
