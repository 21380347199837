import React from 'react';

function ResultPage() {
  return (
    <div className="result-page">
      <h1>Test Results</h1>
      Name: Joseph Kunzler
        IQ: 131
    </div>
  );
}

export default ResultPage;
