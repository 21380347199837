import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-5YYBM7L4XC');
};

export const logPageView = () => {
  console.log('Google Analytics Initialized');
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  console.log(`Logging pageview for ${window.location.pathname}`);
};