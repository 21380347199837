import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Text,
  VStack,
  Heading,
  Container,
  SimpleGrid,
} from '@chakra-ui/react';

const QuestionPage = () => {
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
  const totalQuestions = 30; // Total number of questions
  let history = useHistory();

  useEffect(() => {
    fetchQuestion(currentQuestionNumber);
  }, [currentQuestionNumber]);

  const fetchQuestion = async (questionNumber) => {
    try {
      const response = await axios.get(`http://localhost:3000/questions/${questionNumber}`);
      setCurrentQuestion(response.data);
    } catch (error) {
      console.error('Error fetching question:', error);
    }
  };

  const handleAnswerSubmit = async (answer) => {
    try {
      await axios.post(`http://localhost:3000/api/answers`, {
        questionNumber: currentQuestionNumber,
        answer: answer,
      });
      if (currentQuestionNumber < totalQuestions) {
        setCurrentQuestionNumber(currentQuestionNumber + 1);
      } else {
        history.push('/result');
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
    }
  };

  if (!currentQuestion) {
    return <Box textAlign="center">Loading question...</Box>;
  }

  return (
    <Container maxW="container.md" centerContent py={8}>
      <VStack spacing={6} align="stretch">
        <Box textAlign="center">
          <Heading as="h2" size="xl" mb={4}>Question {currentQuestionNumber}</Heading>
          <Text fontSize="lg" mb={8}>{currentQuestion.content}</Text>
        </Box>
        <SimpleGrid columns={2} spacing={4}>
          {currentQuestion.options?.map((option, index) => (
            <Button key={index} onClick={() => handleAnswerSubmit(option)} colorScheme="blue" size="lg">
              {option}
            </Button>
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
};

export default QuestionPage;

