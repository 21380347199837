import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import TestPage from './components/pages/TestPage';
import ResultPage from './components/pages/ResultPage';
import QuestionPage from './components/pages/QuestionPage';
import { initGA, logPageView } from './analytics';
import { ChakraProvider } from '@chakra-ui/react'


function App() {
  return (
    <ChakraProvider>
    <Router>
      <div className="App">
        <Routing />
      </div>
    </Router>
    </ChakraProvider>
  );
}

// A component to handle route changes and analytics
function Routing() {
  const history = useHistory();

  useEffect(() => {
    initGA();
    logPageView();
    // Listen for page changes
    const unlisten = history.listen(() => {
      logPageView();
    });
    return unlisten; // Cleanup the listener on component unmount
  }, [history]);

  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/question" component={QuestionPage} />
      <Route path="/result" component={ResultPage} />
      <Route path="/test" component={TestPage} />
      <Route path="/history" component={() => <div>History</div>} />
      <Route path="/pricing" component={() => <div>Pricing</div>} />
      <Route path="*" component={() => <div>404 Not Found</div>} />
    </Switch>
  );
}

export default App;



